import { PublicClientApplication } from "@azure/msal-browser"

let auth
let accessToken = ""

export async function init(config) {
    auth = new PublicClientApplication(config)
    await auth.handleRedirectPromise()
}

export const AuthPlugin = {
    install(app, config) {
        app.config.globalProperties.$auth = {
            async login() {
                try {               
                if (auth.getAllAccounts().length > 0) {
                    auth.setActiveAccount(auth.getAllAccounts()[0]);
                   
                    const result = await auth.acquireTokenSilent({
                        scopes: ["0b300757-9fe3-4891-b0fc-feeada26c5d8/.default"],
                        redirectUri: config.auth.redirectUri
                    })
                    accessToken = result.accessToken                    
                    return accessToken
                } else {
                    await auth.acquireTokenRedirect({
                        redirectStartPage: location.href,
                        scopes: ["0b300757-9fe3-4891-b0fc-feeada26c5d8/.default"],
                        redirectUri: config.auth.redirectUri
                    })
                    }
                }
                catch (error) {
                    await auth.acquireTokenRedirect({
                        redirectStartPage: location.href,
                        scopes: ["0b300757-9fe3-4891-b0fc-feeada26c5d8/.default"],
                        redirectUri: config.auth.redirectUri
                    });                  
                }
            },
            async logout() {
                try {
                    await auth.logoutRedirect();
                }
                catch (error) {
                    await auth.acquireTokenRedirect({
                        redirectStartPage: location.href,
                        scopes: ["0b300757-9fe3-4891-b0fc-feeada26c5d8/.default"],
                        redirectUri: config.auth.redirectUri
                    });        
                }
            },
            async accessToken() {            
                const result = await auth.acquireTokenSilent({
                    scopes: ["0b300757-9fe3-4891-b0fc-feeada26c5d8/.default"],
                    redirectUri: config.auth.redirectUri
                })
                    accessToken = result.accessToken
                    return accessToken;               
            },
            getCurrentUser() {
                return auth.getActiveAccount();
            }
        }
    }
}
