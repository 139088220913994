export const config = {
    auth: {
        authority: "https://login.microsoftonline.com/05a6dd31-dbec-4a4f-a8cd-2eada561cecf",
        clientId: "0b300757-9fe3-4891-b0fc-feeada26c5d8",
        redirectUri: "/oauth2/login/aad/callback"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    }
};